// React
import { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

// Material
import { makeStyles, Grid, Box, Theme } from "@material-ui/core";
import CardActivity2 from "../../features/CardActivity/CardActivity2";
import { User } from "../../models/user.model";
// Query
import {
  GutenbergProduct,
  LessonNewsDataFragment,
  OrderDirection,
  QuizNewsDataFragment,
  useLessonsNewsLazyQuery,
  useQuizNewsLazyQuery,
} from "../../graphql";
import { FakeCardActivity } from "../../features/CardActivity/CardActivity";
import NoResult from "../../features/NoResult/NoResult";
import { StoreState } from "../../store";
import { useSelector } from "react-redux";
import { DEFAULT_DOMAIN, DOMAINS } from "../../models/domain.model";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "10%",
      paddingRight: "10%",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    "@supports (-webkit-touch-callout: none)": {
      marginBottom: 149,
    },
  },
  bandeau: {
    width: "100%",
    marginBottom: 12,
  },
}));
/**
 * News component
 */
const News = withRouter(({ match }) => {
  const classes = useStyles();

  const [activityList, setActivityList] = useState<
  (LessonNewsDataFragment | QuizNewsDataFragment)[]
>([]);

  const [
    getLessonsNews,
    {
      data: lessonListData,
      loading: loadingLessonList,
      error: errorLessonList,
    },
  ] = useLessonsNewsLazyQuery({
    errorPolicy: "ignore",
    fetchPolicy: "cache-and-network",
  });

  const currentDomain =
    DOMAINS.find((domain) => match.params.domainSlug.includes(domain.slug)) ??
    DEFAULT_DOMAIN;

   /** Processing time state */
   const user: User | null = useSelector((state: StoreState) => state.user);

  const [
    getQuizzesNews,
    { data: quizListdata, loading: loadingQuizList, error: errorQuizList },
  ] = useQuizNewsLazyQuery({
    errorPolicy: "ignore",
    fetchPolicy: "cache-and-network",
  });

  const mergedActivities = useMemo(() => {
    const lessons = lessonListData?.lessons_2406?.data || [];
    const quizzes = quizListdata?.quizzes_2406?.data || [];
    const combined = [...lessons, ...quizzes];

    const uniqueActivities = new Map<
      string,
      LessonNewsDataFragment | QuizNewsDataFragment
    >();
    combined.forEach((item) => uniqueActivities.set(item._id, item));

    return Array.from(uniqueActivities.values()).sort(
      (a, b) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    );
  }, [lessonListData, quizListdata]);


   /**
   * Use effect get activity list
   */
   useEffect(() => {
    getLessonsNews({
      variables: {
        search: {
          tags: ["A_LA_UNE"],
          products: [GutenbergProduct.RfFormation],
        },
        pagination: {
          order: OrderDirection.Desc,
          page: 1,
          per_page: 200,
        },
      },
    });

    getQuizzesNews({
      variables: {
        search: {
          tags: ["A_LA_UNE"],
          products: [GutenbergProduct.RfFormation],
        },
        pagination: {
          order: OrderDirection.Desc,
          page: 1,
          per_page: 200,
        },
      },
    });
  }, [getLessonsNews, getQuizzesNews]);

  useEffect(() => {
    setActivityList(mergedActivities);
  }, [mergedActivities]);

  return (
    <div>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>À la une</title>
      </Helmet>
      <Grid
        container={true}
        direction="column"
        spacing={2}
        className={classes.container}
      >
        {/*  <img
        src={bandeau}
        alt="Nous vous souhaitons bonne fete"
        className={classes.bandeau}
      /> */}
      </Grid>
      {loadingLessonList || loadingQuizList ? (
        <Box marginTop={3}>
          <FakeCardActivity />
        </Box>
      ) : !loadingLessonList &&
        !loadingQuizList &&
        errorLessonList &&
        errorQuizList ? (
        <NoResult/>
      ) : (
        <>
          {!loadingLessonList &&
        !loadingQuizList && activityList &&
            activityList.filter((activity) =>
              user.subscription.domainsAccessEl.some((domain) =>
                activity.parentCategories[0]?.fullPath.includes(domain.toString()) && activity.parentCategories[0]?.fullPath.includes(currentDomain.fullPath)
              )).map((activity) => {
              return (
                <Box id={activity._id} key={activity._id} marginTop={1}>
                  <CardActivity2 activity={activity} />
                </Box>
              );
            })}
        </>
      )}
    </div>
  );
});

export default News;
